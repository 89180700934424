import React from "react";
import { UpdateRoleMutationVariables } from "../../../types/graphql";
import ChoosePermissionItem from "../ChoosePermissionItem";
import RadioBtnsComponent from "../RadioBtnsComponent";

import "./styles.css";

interface IPermissions {
    fields: UpdateRoleMutationVariables;
    setFields: React.Dispatch<React.SetStateAction<UpdateRoleMutationVariables>>;
}

const ManageRolePermissions: React.FC<IPermissions> = ({ fields, setFields }) => {
    const changePermission = (nameOfPermission: string, value: boolean) => {
        setFields((prevState) => {
            return {
                ...prevState,
                permissions: {
                    ...prevState?.permissions,
                    [nameOfPermission]: !value,
                },
            };
        });
    };

    return (
        <div className="manage-role-permissions">
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"1. ДОСТУП К СТРАНИЦЕ 'ПОЛЬЗОВАТЕЛИ'"}
                    nameOfPermission={"accessUserPage"}
                    value={fields?.permissions?.accessUserPage || false}
                    changePermission={changePermission}
                />
                <div className="manage-role_sub-group">
                    <div className="manage-role__subtitle">ДОСТУП</div>
                    <RadioBtnsComponent
                        changeableField="accessAllGroups"
                        fields={fields}
                        setFields={setFields}
                        radio1Title="Все группы"
                        radio2Title="Принадлежащие роли группы"
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.1 СОЗДАНИЕ ГРУПП"}
                        nameOfPermission={"createGroup"}
                        value={fields?.permissions?.createGroup || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.2 РЕГИСТРАЦИЯ УЧАСТНИКОВ"}
                        nameOfPermission={"registerUser"}
                        value={fields?.permissions?.registerUser || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.3 ВИДЕТЬ ДАННЫЕ УЧАСТНИКОВ(ЛИЧНЫЕ)"}
                        nameOfPermission={"seeUserData"}
                        value={fields?.permissions?.seeUserData || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <div className="manage-role_sub-group">
                        <ChoosePermissionItem
                            name={"1.3.1 РЕДАКТИРОВАНИЕ ДАННЫХ УЧАСТНИКОВ"}
                            nameOfPermission={"editUserData"}
                            value={fields?.permissions?.editUserData || false}
                            changePermission={changePermission}
                            disabled={!fields.permissions.seeUserData}
                        />
                        <ChoosePermissionItem
                            name={"1.3.2 РЕДАКТИРОВАНИЕ ДАННЫХ ОПЛАТ"}
                            nameOfPermission={"editPayments"}
                            value={fields?.permissions?.editPayments || false}
                            changePermission={changePermission}
                            disabled={!fields.permissions.seeUserData}
                        />
                        <div className="manage-role_sub-group">
                            <ChoosePermissionItem
                                name={"РЕДАКТИРОВАНИЕ БАЛАНСА"}
                                nameOfPermission={"editBalance"}
                                value={fields?.permissions?.editBalance || false}
                                changePermission={changePermission}
                                disabled={!fields.permissions.editPayments}
                            />
                            <ChoosePermissionItem
                                name={"РЕДАКТИРОВАНИЕ БЕСПЛАТНЫХ ПОЕДИНКОВ"}
                                nameOfPermission={"giveFreeDuels"}
                                value={fields?.permissions?.giveFreeDuels || false}
                                changePermission={changePermission}
                                disabled={!fields.permissions.editPayments}
                            />
                            <ChoosePermissionItem
                                name={"РЕДАКТИРОВАНИЕ ДАТЫ СТАРТА/ОКОНЧАНИЯ АБОНЕМЕНТА"}
                                nameOfPermission={"editDateMembership"}
                                value={fields?.permissions?.editDateMembership || false}
                                changePermission={changePermission}
                                disabled={!fields.permissions.editPayments}
                            />
                            <ChoosePermissionItem
                                name={"РЕДАКТИРОВАНИЕ ДАТЫ СТАРТА/ОКОНЧАНИЯ ТРЕНИНГА"}
                                nameOfPermission={"editDateTraining"}
                                value={fields?.permissions?.editDateTraining || false}
                                changePermission={changePermission}
                                disabled={!fields.permissions.editPayments}
                            />
                        </div>
                    </div>
                    <ChoosePermissionItem
                        name={"1.4. ОТОБРАЖЕНИЕ ОПЛАТ УЧАСТНИКОВ(ТРАНЗАКЦИЙ)"}
                        nameOfPermission={"seeTransactions"}
                        value={fields?.permissions?.seeTransactions || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.5. ВОЗМОЖНОСТЬ НАЗНАЧЕНИЯ ВРЕМЕНИ УЧАСТИЯ ДЛЯ КАЖДОЙ ДОСТУПНОЙ ГРУППЫ"}
                        nameOfPermission={"setDuelTime"}
                        value={fields?.permissions?.setDuelTime || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.6. НАЗНАЧЕНИЕ ТИПА ПОЕДИНКА"}
                        nameOfPermission={"setDuelType"}
                        value={fields?.permissions?.setDuelType || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.7. НАЗНАЧЕНИЕ СИТУАЦИЙ"}
                        nameOfPermission={"setTasks"}
                        value={fields?.permissions?.setTasks || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.8. НАЗНАЧЕНИЕ СОАДМИНИСТРАТОРОВ ГРУПП"}
                        nameOfPermission={"setAdmin"}
                        value={fields?.permissions?.setAdmin || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <ChoosePermissionItem
                        name={"1.9. ПЛАНИРОВАНИЕ ПОЕДИНКОВ"}
                        nameOfPermission={"organizeDuel"}
                        value={fields?.permissions?.organizeDuel || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.accessUserPage}
                    />
                    <div className="manage-role_sub-group">
                        <ChoosePermissionItem
                            name={"1.9.1. ТРАНСЛЯЦИЯ ПОЕДИНКОВ В YOUTUBE"}
                            nameOfPermission={"broadcastYoutube"}
                            value={fields?.permissions?.broadcastYoutube || false}
                            changePermission={changePermission}
                            disabled={!fields.permissions.organizeDuel}
                        />
                    </div>
                </div>
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"2. СОЗДАНИЕ СИТУАЦИЙ"}
                    nameOfPermission={"createTask"}
                    value={fields?.permissions?.createTask || false}
                    changePermission={changePermission}
                />
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"3. ВОЗМОЖНОСТЬ БЕСПЛАТНОГО УЧАСТИЯ"}
                    nameOfPermission={"participateFree"}
                    value={fields?.permissions?.participateFree || false}
                    changePermission={changePermission}
                />
                <div className="manage-role_sub-group group-checkboxes">
                    <ChoosePermissionItem
                        name={"СУДЬЕЙ"}
                        nameOfPermission={"participateFreeJudge"}
                        value={fields?.permissions?.participateFreeJudge || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.participateFree}
                    />
                    <ChoosePermissionItem
                        name={"ВЕДУЩИМ"}
                        nameOfPermission={"participateFreeMaster"}
                        value={fields?.permissions?.participateFreeMaster || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.participateFree}
                    />
                    <ChoosePermissionItem
                        name={"УЧАСТНИКОМ"}
                        nameOfPermission={"participateFreePlayer"}
                        value={fields?.permissions?.participateFreePlayer || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.participateFree}
                    />
                </div>
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"4. ВОЗМОЖНОСТЬ ПРОСМОТРА ВИДЕО ПОЕДИНКОВ"}
                    nameOfPermission={"seeVideo"}
                    value={fields?.permissions?.seeVideo || false}
                    changePermission={changePermission}
                />
                <div className="manage-role_sub-group">
                    <RadioBtnsComponent
                        changeableField="seeVideoAllGroups"
                        fields={fields}
                        setFields={setFields}
                        radio1Title="Все группы"
                        radio2Title="Группы, к которым принадлежит участник"
                        disabled={!fields.permissions.seeVideo}
                    />
                    <div className="group-checkboxes">
                        <ChoosePermissionItem
                            name={"ЗАВЕРШЁННЫЕ"}
                            nameOfPermission={"seeVideoCompleted"}
                            value={fields?.permissions?.seeVideoCompleted || false}
                            changePermission={changePermission}
                            disabled={!fields.permissions.seeVideo}
                        />
                        <ChoosePermissionItem
                            name={"НЕЗАВЕРШЁННЫЕ"}
                            nameOfPermission={"seeVideoNotCompleted"}
                            value={fields?.permissions?.seeVideoNotCompleted || false}
                            changePermission={changePermission}
                            disabled={!fields.permissions.seeVideo}
                        />
                    </div>
                </div>
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"5. УЧАСТИЕ В ОБЩЕМ ЧАТЕ АДМИНИСТРАТОРОВ НА ПЛАТФОРМЕ"}
                    nameOfPermission={"adminChat"}
                    value={fields?.permissions?.adminChat || false}
                    changePermission={changePermission}
                />
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"6. ПРАВО СОЗДАВАТЬ И РЕДАКТИРОВАТЬ СОЗДАННЫЕ ИНСТРУКЦИИ"}
                    nameOfPermission={"createInstruction"}
                    value={fields?.permissions?.createInstruction || false}
                    changePermission={changePermission}
                    disabled={false}
                />
                <div className="manage-role_sub-group">
                    <ChoosePermissionItem
                        name={"6.1. ПРАВО СОЗДАВАТЬ И РЕДАКТИРОВАТЬ ИНСТРУКЦИИ"}
                        nameOfPermission={"createInstruction"}
                        value={fields?.permissions?.createInstruction || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.createInstruction}
                    />
                    <ChoosePermissionItem
                        name={"6.2. НАЗНАЧАТЬ СВОИ СОЗДАННЫЕ ИНСТРУКЦИИ ДЛЯ ГРУПП"}
                        nameOfPermission={"setInstruction"}
                        value={fields?.permissions?.setInstruction || false}
                        changePermission={changePermission}
                        disabled={!fields.permissions.createInstruction}
                    />
                </div>
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"7. ВОЗМОЖНОСТЬ АДМИНИСТРИРОВАНИЯ БЛ"}
                    nameOfPermission={"bcAdmin"}
                    value={fields?.permissions?.bcAdmin || false}
                    changePermission={changePermission}
                />
            </div>
            <div className="manage-role_permission-container">
                <ChoosePermissionItem
                    name={"8. ВОЗМОЖНОСТЬ ВИДЕТЬ СТАТИСТИКУ МЕРОПРИЯТИЙ"}
                    nameOfPermission={"seeEventStatistic"}
                    value={fields?.permissions?.seeEventStatistic || false}
                    changePermission={changePermission}
                />
            </div>
        </div>
    );
};

export default ManageRolePermissions;
