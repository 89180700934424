import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AdditionalInfoInput = {
  age?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  howKnew?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  jobPosition?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<Scalars['String']>;
  whyChoose?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addRole?: Maybe<OkMessage>;
  addType?: Maybe<OkMessage>;
  deleteRole?: Maybe<OkMessage>;
  deleteType?: Maybe<OkMessage>;
  signup?: Maybe<OkMessage>;
  updateRole?: Maybe<OkMessage>;
  updateType?: Maybe<OkMessage>;
};


export type MutationAddRoleArgs = {
  name: Scalars['String'];
  permissions: PermissionsInput;
};


export type MutationAddTypeArgs = {
  allTime: Scalars['Boolean'];
  connectDuel: Scalars['Boolean'];
  createDuel: Scalars['Boolean'];
  duelTypes?: InputMaybe<Array<Scalars['String']>>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  periods?: InputMaybe<Array<InputMaybe<PeriodInput>>>;
  tasks?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTypeArgs = {
  id: Scalars['ID'];
};


export type MutationSignupArgs = {
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
  comment?: InputMaybe<Scalars['String']>;
  communication: Array<InputMaybe<Scalars['String']>>;
  email: Scalars['String'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  subscribed: Scalars['Boolean'];
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: PermissionsInput;
};


export type MutationUpdateTypeArgs = {
  allTime: Scalars['Boolean'];
  connectDuel: Scalars['Boolean'];
  createDuel: Scalars['Boolean'];
  duelTypes?: InputMaybe<Array<Scalars['String']>>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  periods?: InputMaybe<Array<InputMaybe<PeriodInput>>>;
  tasks?: InputMaybe<Array<Scalars['String']>>;
};

export type OkMessage = {
  __typename?: 'OkMessage';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type Period = {
  __typename?: 'Period';
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  weekDay?: Maybe<Scalars['Int']>;
};

export type PeriodInput = {
  timeFrom?: InputMaybe<Scalars['String']>;
  timeTo?: InputMaybe<Scalars['String']>;
  weekDay?: InputMaybe<Scalars['Int']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  accessAllGroups?: Maybe<Scalars['Boolean']>;
  accessUserPage?: Maybe<Scalars['Boolean']>;
  adminChat?: Maybe<Scalars['Boolean']>;
  bcAdmin?: Maybe<Scalars['Boolean']>;
  broadcastYoutube?: Maybe<Scalars['Boolean']>;
  createGroup?: Maybe<Scalars['Boolean']>;
  createInstruction?: Maybe<Scalars['Boolean']>;
  createTask?: Maybe<Scalars['Boolean']>;
  editBalance?: Maybe<Scalars['Boolean']>;
  editDateMembership?: Maybe<Scalars['Boolean']>;
  editDateTraining?: Maybe<Scalars['Boolean']>;
  editPayments?: Maybe<Scalars['Boolean']>;
  editUserData?: Maybe<Scalars['Boolean']>;
  giveFreeDuels?: Maybe<Scalars['Boolean']>;
  organizeDuel?: Maybe<Scalars['Boolean']>;
  participateFree?: Maybe<Scalars['Boolean']>;
  participateFreeJudge?: Maybe<Scalars['Boolean']>;
  participateFreeMaster?: Maybe<Scalars['Boolean']>;
  participateFreePlayer?: Maybe<Scalars['Boolean']>;
  registerUser?: Maybe<Scalars['Boolean']>;
  seeEventStatistic?: Maybe<Scalars['Boolean']>;
  seeTransactions?: Maybe<Scalars['Boolean']>;
  seeUserData?: Maybe<Scalars['Boolean']>;
  seeVideo?: Maybe<Scalars['Boolean']>;
  seeVideoAllGroups?: Maybe<Scalars['Boolean']>;
  seeVideoCompleted?: Maybe<Scalars['Boolean']>;
  seeVideoNotCompleted?: Maybe<Scalars['Boolean']>;
  setAdmin?: Maybe<Scalars['Boolean']>;
  setDuelTime?: Maybe<Scalars['Boolean']>;
  setDuelType?: Maybe<Scalars['Boolean']>;
  setInstruction?: Maybe<Scalars['Boolean']>;
  setTasks?: Maybe<Scalars['Boolean']>;
};

export type PermissionsInput = {
  accessAllGroups?: InputMaybe<Scalars['Boolean']>;
  accessUserPage?: InputMaybe<Scalars['Boolean']>;
  adminChat?: InputMaybe<Scalars['Boolean']>;
  bcAdmin?: InputMaybe<Scalars['Boolean']>;
  broadcastYoutube?: InputMaybe<Scalars['Boolean']>;
  createGroup?: InputMaybe<Scalars['Boolean']>;
  createInstruction?: InputMaybe<Scalars['Boolean']>;
  createTask?: InputMaybe<Scalars['Boolean']>;
  editBalance?: InputMaybe<Scalars['Boolean']>;
  editDateMembership?: InputMaybe<Scalars['Boolean']>;
  editDateTraining?: InputMaybe<Scalars['Boolean']>;
  editPayments?: InputMaybe<Scalars['Boolean']>;
  editUserData?: InputMaybe<Scalars['Boolean']>;
  giveFreeDuels?: InputMaybe<Scalars['Boolean']>;
  organizeDuel?: InputMaybe<Scalars['Boolean']>;
  participateFree?: InputMaybe<Scalars['Boolean']>;
  participateFreeJudge?: InputMaybe<Scalars['Boolean']>;
  participateFreeMaster?: InputMaybe<Scalars['Boolean']>;
  participateFreePlayer?: InputMaybe<Scalars['Boolean']>;
  registerUser?: InputMaybe<Scalars['Boolean']>;
  seeEventStatistic?: InputMaybe<Scalars['Boolean']>;
  seeTransactions?: InputMaybe<Scalars['Boolean']>;
  seeUserData?: InputMaybe<Scalars['Boolean']>;
  seeVideo?: InputMaybe<Scalars['Boolean']>;
  seeVideoAllGroups?: InputMaybe<Scalars['Boolean']>;
  seeVideoCompleted?: InputMaybe<Scalars['Boolean']>;
  seeVideoNotCompleted?: InputMaybe<Scalars['Boolean']>;
  setAdmin?: InputMaybe<Scalars['Boolean']>;
  setDuelTime?: InputMaybe<Scalars['Boolean']>;
  setDuelType?: InputMaybe<Scalars['Boolean']>;
  setInstruction?: InputMaybe<Scalars['Boolean']>;
  setTasks?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<UserCredentials>;
  checkNewEmail?: Maybe<OkMessage>;
  countryFlagCode?: Maybe<Scalars['String']>;
  requestAuth?: Maybe<OkMessage>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  type?: Maybe<Type>;
  types?: Maybe<Types>;
};


export type QueryAuthArgs = {
  email: Scalars['String'];
  tempPassword: Scalars['String'];
};


export type QueryCheckNewEmailArgs = {
  email: Scalars['String'];
};


export type QueryCountryFlagCodeArgs = {
  userIp: Scalars['String'];
};


export type QueryRequestAuthArgs = {
  email: Scalars['String'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryTypeArgs = {
  id: Scalars['ID'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Permissions;
};

export type TaskInfo = {
  __typename?: 'TaskInfo';
  description?: Maybe<Scalars['String']>;
  duelType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  role1?: Maybe<Scalars['String']>;
  role2?: Maybe<Scalars['String']>;
};

export type Type = {
  __typename?: 'Type';
  allTime: Scalars['Boolean'];
  connectDuel: Scalars['Boolean'];
  createDuel: Scalars['Boolean'];
  duelTypes?: Maybe<Array<Scalars['String']>>;
  events?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentMethods?: Maybe<Array<Scalars['String']>>;
  periods?: Maybe<Array<Maybe<Period>>>;
  tasks?: Maybe<Array<TaskInfo>>;
};

export type Types = {
  __typename?: 'Types';
  allEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  tasks?: Maybe<Array<Maybe<TaskInfo>>>;
  types?: Maybe<Array<Maybe<Type>>>;
};

export type UserCredentials = {
  __typename?: 'UserCredentials';
  accessToken?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type SignupMutationVariables = Exact<{
  name: Scalars['String'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  communication: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
  comment?: InputMaybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddRoleMutationVariables = Exact<{
  name: Scalars['String'];
  permissions: PermissionsInput;
}>;


export type AddRoleMutation = (
  { __typename?: 'Mutation' }
  & { addRole?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: PermissionsInput;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddTypeMutationVariables = Exact<{
  name: Scalars['String'];
  duelTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  tasks?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  connectDuel: Scalars['Boolean'];
  createDuel: Scalars['Boolean'];
  periods?: InputMaybe<Array<InputMaybe<PeriodInput>> | InputMaybe<PeriodInput>>;
  allTime: Scalars['Boolean'];
  paymentMethods?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type AddTypeMutation = (
  { __typename?: 'Mutation' }
  & { addType?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  duelTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  tasks?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  connectDuel: Scalars['Boolean'];
  createDuel: Scalars['Boolean'];
  periods?: InputMaybe<Array<InputMaybe<PeriodInput>> | InputMaybe<PeriodInput>>;
  allTime: Scalars['Boolean'];
  paymentMethods?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateType?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteType?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type RequestAuthQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestAuthQuery = (
  { __typename?: 'Query' }
  & { requestAuth?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type CheckNewEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckNewEmailQuery = (
  { __typename?: 'Query' }
  & { checkNewEmail?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type CountryFlagCodeQueryVariables = Exact<{
  userIp: Scalars['String'];
}>;


export type CountryFlagCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countryFlagCode'>
);

export type AuthQueryVariables = Exact<{
  email: Scalars['String'];
  tempPassword: Scalars['String'];
}>;


export type AuthQuery = (
  { __typename?: 'Query' }
  & { auth?: Maybe<(
    { __typename?: 'UserCredentials' }
    & Pick<UserCredentials, 'accessToken' | 'isAdmin'>
  )> }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<Permissions, 'accessUserPage' | 'accessAllGroups' | 'createGroup' | 'registerUser' | 'seeUserData' | 'editUserData' | 'editPayments' | 'editBalance' | 'giveFreeDuels' | 'editDateTraining' | 'editDateMembership' | 'seeTransactions' | 'setDuelTime' | 'setDuelType' | 'setTasks' | 'setAdmin' | 'organizeDuel' | 'broadcastYoutube' | 'createTask' | 'participateFree' | 'participateFreeJudge' | 'participateFreeMaster' | 'participateFreePlayer' | 'seeVideo' | 'seeVideoAllGroups' | 'seeVideoCompleted' | 'seeVideoNotCompleted' | 'adminChat' | 'createInstruction' | 'setInstruction' | 'bcAdmin' | 'seeEventStatistic'>
    ) }
  )>>> }
);

export type RoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleQuery = (
  { __typename?: 'Query' }
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<Permissions, 'accessUserPage' | 'accessAllGroups' | 'createGroup' | 'registerUser' | 'seeUserData' | 'editUserData' | 'editPayments' | 'editBalance' | 'giveFreeDuels' | 'editDateMembership' | 'editDateTraining' | 'seeTransactions' | 'setDuelTime' | 'setDuelType' | 'setTasks' | 'setAdmin' | 'organizeDuel' | 'broadcastYoutube' | 'createTask' | 'participateFree' | 'participateFreeJudge' | 'participateFreeMaster' | 'participateFreePlayer' | 'seeVideo' | 'seeVideoAllGroups' | 'seeVideoCompleted' | 'seeVideoNotCompleted' | 'adminChat' | 'createInstruction' | 'setInstruction' | 'bcAdmin' | 'seeEventStatistic'>
    ) }
  )> }
);

export type TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type TypesQuery = (
  { __typename?: 'Query' }
  & { types?: Maybe<(
    { __typename?: 'Types' }
    & Pick<Types, 'allEvents'>
    & { types?: Maybe<Array<Maybe<(
      { __typename?: 'Type' }
      & Pick<Type, 'id' | 'name' | 'duelTypes' | 'connectDuel' | 'createDuel' | 'allTime' | 'paymentMethods' | 'events'>
      & { tasks?: Maybe<Array<(
        { __typename?: 'TaskInfo' }
        & Pick<TaskInfo, 'id' | 'name'>
      )>>, periods?: Maybe<Array<Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'weekDay' | 'timeFrom' | 'timeTo'>
      )>>> }
    )>>>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'TaskInfo' }
      & Pick<TaskInfo, 'id' | 'name' | 'role1' | 'role2' | 'duelType' | 'description'>
    )>>> }
  )> }
);

export type TypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TypeQuery = (
  { __typename?: 'Query' }
  & { type?: Maybe<(
    { __typename?: 'Type' }
    & Pick<Type, 'id' | 'name' | 'duelTypes' | 'connectDuel' | 'createDuel' | 'allTime' | 'paymentMethods' | 'events'>
    & { tasks?: Maybe<Array<(
      { __typename?: 'TaskInfo' }
      & Pick<TaskInfo, 'id' | 'name'>
    )>>, periods?: Maybe<Array<Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'weekDay' | 'timeFrom' | 'timeTo'>
    )>>> }
  )> }
);


export const SignupDocument = gql`
    mutation Signup($name: String!, $lastname: String!, $middlename: String, $email: String!, $phone: String!, $communication: [String]!, $additionalInfo: AdditionalInfoInput, $comment: String, $subscribed: Boolean!) {
  signup(
    name: $name
    lastname: $lastname
    middlename: $middlename
    email: $email
    phone: $phone
    communication: $communication
    additionalInfo: $additionalInfo
    comment: $comment
    subscribed: $subscribed
  ) {
    message
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      middlename: // value for 'middlename'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      communication: // value for 'communication'
 *      additionalInfo: // value for 'additionalInfo'
 *      comment: // value for 'comment'
 *      subscribed: // value for 'subscribed'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const AddRoleDocument = gql`
    mutation addRole($name: String!, $permissions: PermissionsInput!) {
  addRole(name: $name, permissions: $permissions) {
    id
    message
  }
}
    `;
export type AddRoleMutationFn = Apollo.MutationFunction<AddRoleMutation, AddRoleMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAddRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleMutation, AddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(AddRoleDocument, options);
      }
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleMutation, AddRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: ID!, $name: String!, $permissions: PermissionsInput!) {
  updateRole(id: $id, name: $name, permissions: $permissions) {
    id
    message
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($id: ID!) {
  deleteRole(id: $id) {
    message
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const AddTypeDocument = gql`
    mutation addType($name: String!, $duelTypes: [String!], $tasks: [String!], $connectDuel: Boolean!, $createDuel: Boolean!, $periods: [PeriodInput], $allTime: Boolean!, $paymentMethods: [String!], $events: [String]) {
  addType(
    name: $name
    duelTypes: $duelTypes
    tasks: $tasks
    connectDuel: $connectDuel
    createDuel: $createDuel
    periods: $periods
    allTime: $allTime
    paymentMethods: $paymentMethods
    events: $events
  ) {
    id
    message
  }
}
    `;
export type AddTypeMutationFn = Apollo.MutationFunction<AddTypeMutation, AddTypeMutationVariables>;

/**
 * __useAddTypeMutation__
 *
 * To run a mutation, you first call `useAddTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeMutation, { data, loading, error }] = useAddTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      duelTypes: // value for 'duelTypes'
 *      tasks: // value for 'tasks'
 *      connectDuel: // value for 'connectDuel'
 *      createDuel: // value for 'createDuel'
 *      periods: // value for 'periods'
 *      allTime: // value for 'allTime'
 *      paymentMethods: // value for 'paymentMethods'
 *      events: // value for 'events'
 *   },
 * });
 */
export function useAddTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddTypeMutation, AddTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTypeMutation, AddTypeMutationVariables>(AddTypeDocument, options);
      }
export type AddTypeMutationHookResult = ReturnType<typeof useAddTypeMutation>;
export type AddTypeMutationResult = Apollo.MutationResult<AddTypeMutation>;
export type AddTypeMutationOptions = Apollo.BaseMutationOptions<AddTypeMutation, AddTypeMutationVariables>;
export const UpdateTypeDocument = gql`
    mutation updateType($id: ID!, $name: String!, $duelTypes: [String!], $tasks: [String!], $connectDuel: Boolean!, $createDuel: Boolean!, $periods: [PeriodInput], $allTime: Boolean!, $paymentMethods: [String!], $events: [String]) {
  updateType(
    id: $id
    name: $name
    duelTypes: $duelTypes
    tasks: $tasks
    connectDuel: $connectDuel
    createDuel: $createDuel
    periods: $periods
    allTime: $allTime
    paymentMethods: $paymentMethods
    events: $events
  ) {
    id
    message
  }
}
    `;
export type UpdateTypeMutationFn = Apollo.MutationFunction<UpdateTypeMutation, UpdateTypeMutationVariables>;

/**
 * __useUpdateTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTypeMutation, { data, loading, error }] = useUpdateTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      duelTypes: // value for 'duelTypes'
 *      tasks: // value for 'tasks'
 *      connectDuel: // value for 'connectDuel'
 *      createDuel: // value for 'createDuel'
 *      periods: // value for 'periods'
 *      allTime: // value for 'allTime'
 *      paymentMethods: // value for 'paymentMethods'
 *      events: // value for 'events'
 *   },
 * });
 */
export function useUpdateTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTypeMutation, UpdateTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTypeMutation, UpdateTypeMutationVariables>(UpdateTypeDocument, options);
      }
export type UpdateTypeMutationHookResult = ReturnType<typeof useUpdateTypeMutation>;
export type UpdateTypeMutationResult = Apollo.MutationResult<UpdateTypeMutation>;
export type UpdateTypeMutationOptions = Apollo.BaseMutationOptions<UpdateTypeMutation, UpdateTypeMutationVariables>;
export const DeleteTypeDocument = gql`
    mutation deleteType($id: ID!) {
  deleteType(id: $id) {
    message
  }
}
    `;
export type DeleteTypeMutationFn = Apollo.MutationFunction<DeleteTypeMutation, DeleteTypeMutationVariables>;

/**
 * __useDeleteTypeMutation__
 *
 * To run a mutation, you first call `useDeleteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTypeMutation, { data, loading, error }] = useDeleteTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTypeMutation, DeleteTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTypeMutation, DeleteTypeMutationVariables>(DeleteTypeDocument, options);
      }
export type DeleteTypeMutationHookResult = ReturnType<typeof useDeleteTypeMutation>;
export type DeleteTypeMutationResult = Apollo.MutationResult<DeleteTypeMutation>;
export type DeleteTypeMutationOptions = Apollo.BaseMutationOptions<DeleteTypeMutation, DeleteTypeMutationVariables>;
export const RequestAuthDocument = gql`
    query RequestAuth($email: String!) {
  requestAuth(email: $email) {
    message
  }
}
    `;

/**
 * __useRequestAuthQuery__
 *
 * To run a query within a React component, call `useRequestAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAuthQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestAuthQuery(baseOptions: Apollo.QueryHookOptions<RequestAuthQuery, RequestAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestAuthQuery, RequestAuthQueryVariables>(RequestAuthDocument, options);
      }
export function useRequestAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestAuthQuery, RequestAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestAuthQuery, RequestAuthQueryVariables>(RequestAuthDocument, options);
        }
export type RequestAuthQueryHookResult = ReturnType<typeof useRequestAuthQuery>;
export type RequestAuthLazyQueryHookResult = ReturnType<typeof useRequestAuthLazyQuery>;
export type RequestAuthQueryResult = Apollo.QueryResult<RequestAuthQuery, RequestAuthQueryVariables>;
export const CheckNewEmailDocument = gql`
    query CheckNewEmail($email: String!) {
  checkNewEmail(email: $email) {
    message
  }
}
    `;

/**
 * __useCheckNewEmailQuery__
 *
 * To run a query within a React component, call `useCheckNewEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckNewEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckNewEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckNewEmailQuery(baseOptions: Apollo.QueryHookOptions<CheckNewEmailQuery, CheckNewEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckNewEmailQuery, CheckNewEmailQueryVariables>(CheckNewEmailDocument, options);
      }
export function useCheckNewEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckNewEmailQuery, CheckNewEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckNewEmailQuery, CheckNewEmailQueryVariables>(CheckNewEmailDocument, options);
        }
export type CheckNewEmailQueryHookResult = ReturnType<typeof useCheckNewEmailQuery>;
export type CheckNewEmailLazyQueryHookResult = ReturnType<typeof useCheckNewEmailLazyQuery>;
export type CheckNewEmailQueryResult = Apollo.QueryResult<CheckNewEmailQuery, CheckNewEmailQueryVariables>;
export const CountryFlagCodeDocument = gql`
    query CountryFlagCode($userIp: String!) {
  countryFlagCode(userIp: $userIp)
}
    `;

/**
 * __useCountryFlagCodeQuery__
 *
 * To run a query within a React component, call `useCountryFlagCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryFlagCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryFlagCodeQuery({
 *   variables: {
 *      userIp: // value for 'userIp'
 *   },
 * });
 */
export function useCountryFlagCodeQuery(baseOptions: Apollo.QueryHookOptions<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>(CountryFlagCodeDocument, options);
      }
export function useCountryFlagCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>(CountryFlagCodeDocument, options);
        }
export type CountryFlagCodeQueryHookResult = ReturnType<typeof useCountryFlagCodeQuery>;
export type CountryFlagCodeLazyQueryHookResult = ReturnType<typeof useCountryFlagCodeLazyQuery>;
export type CountryFlagCodeQueryResult = Apollo.QueryResult<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>;
export const AuthDocument = gql`
    query Auth($email: String!, $tempPassword: String!) {
  auth(email: $email, tempPassword: $tempPassword) {
    accessToken
    isAdmin
  }
}
    `;

/**
 * __useAuthQuery__
 *
 * To run a query within a React component, call `useAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthQuery({
 *   variables: {
 *      email: // value for 'email'
 *      tempPassword: // value for 'tempPassword'
 *   },
 * });
 */
export function useAuthQuery(baseOptions: Apollo.QueryHookOptions<AuthQuery, AuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
      }
export function useAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthQuery, AuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
        }
export type AuthQueryHookResult = ReturnType<typeof useAuthQuery>;
export type AuthLazyQueryHookResult = ReturnType<typeof useAuthLazyQuery>;
export type AuthQueryResult = Apollo.QueryResult<AuthQuery, AuthQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    id
    name
    permissions {
      accessUserPage
      accessAllGroups
      createGroup
      registerUser
      seeUserData
      editUserData
      editPayments
      editBalance
      giveFreeDuels
      editDateTraining
      editDateMembership
      seeTransactions
      setDuelTime
      setDuelType
      setTasks
      setAdmin
      organizeDuel
      broadcastYoutube
      createTask
      participateFree
      participateFreeJudge
      participateFreeMaster
      participateFreePlayer
      seeVideo
      seeVideoAllGroups
      seeVideoCompleted
      seeVideoNotCompleted
      adminChat
      createInstruction
      setInstruction
      bcAdmin
      seeEventStatistic
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const RoleDocument = gql`
    query role($id: ID!) {
  role(id: $id) {
    id
    name
    permissions {
      accessUserPage
      accessAllGroups
      createGroup
      registerUser
      seeUserData
      editUserData
      editPayments
      editBalance
      giveFreeDuels
      editDateMembership
      editDateTraining
      seeTransactions
      setDuelTime
      setDuelType
      setTasks
      setAdmin
      organizeDuel
      broadcastYoutube
      createTask
      participateFree
      participateFreeJudge
      participateFreeMaster
      participateFreePlayer
      seeVideo
      seeVideoAllGroups
      seeVideoCompleted
      seeVideoNotCompleted
      adminChat
      createInstruction
      setInstruction
      bcAdmin
      seeEventStatistic
    }
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const TypesDocument = gql`
    query types {
  types {
    types {
      id
      name
      duelTypes
      tasks {
        id
        name
      }
      connectDuel
      createDuel
      periods {
        weekDay
        timeFrom
        timeTo
      }
      allTime
      paymentMethods
      events
    }
    tasks {
      id
      name
      role1
      role2
      duelType
      description
    }
    allEvents
  }
}
    `;

/**
 * __useTypesQuery__
 *
 * To run a query within a React component, call `useTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypesQuery(baseOptions?: Apollo.QueryHookOptions<TypesQuery, TypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypesQuery, TypesQueryVariables>(TypesDocument, options);
      }
export function useTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypesQuery, TypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypesQuery, TypesQueryVariables>(TypesDocument, options);
        }
export type TypesQueryHookResult = ReturnType<typeof useTypesQuery>;
export type TypesLazyQueryHookResult = ReturnType<typeof useTypesLazyQuery>;
export type TypesQueryResult = Apollo.QueryResult<TypesQuery, TypesQueryVariables>;
export const TypeDocument = gql`
    query type($id: ID!) {
  type(id: $id) {
    id
    name
    duelTypes
    tasks {
      id
      name
    }
    connectDuel
    createDuel
    periods {
      weekDay
      timeFrom
      timeTo
    }
    allTime
    paymentMethods
    events
  }
}
    `;

/**
 * __useTypeQuery__
 *
 * To run a query within a React component, call `useTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeQuery(baseOptions: Apollo.QueryHookOptions<TypeQuery, TypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeQuery, TypeQueryVariables>(TypeDocument, options);
      }
export function useTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeQuery, TypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeQuery, TypeQueryVariables>(TypeDocument, options);
        }
export type TypeQueryHookResult = ReturnType<typeof useTypeQuery>;
export type TypeLazyQueryHookResult = ReturnType<typeof useTypeLazyQuery>;
export type TypeQueryResult = Apollo.QueryResult<TypeQuery, TypeQueryVariables>;