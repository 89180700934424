/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { errorsVar, successErrorType, typeEditVar } from "../../cache/cache";
import { useUpdateTypeMutation, useAddTypeMutation, UpdateTypeMutationVariables, Period } from "../../types/graphql";
import { setError } from "../../misc/common";
import { TYPES } from "../../graphql/queries";
import { DuelType } from "../../types/types";
import { ISelectItem } from "../../components/common/Select";
import Checkbox from "../../components/common/Checkbox";
import PeriodList from "../../components/common/PeriodList";
import ErrorMessage from "../../components/common/ErrorMessage";

import "./styles.css";

const Input = React.lazy(() => import("../../components/common/Input"));
const Button = React.lazy(() => import("../../components/common/Button"));

export type DisplayTask = {
    id: string;
    name: string;
    searchArea: string;
    selected: boolean;
    type: DuelType;
};

const ManageType: React.FC = () => {
    let errors = useReactiveVar(errorsVar);

    const navigate = useNavigate();
    const location = useLocation();
    const editFields = useReactiveVar(typeEditVar);
    interface LocationState {
        allTasks: any[];
        allEvents: string[];
    }
    const locationState = location.state as LocationState;

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
    const [tasks, setTasks] = React.useState<DisplayTask[]>([]);
    const [filteredTasks, setFilteredTasks] = React.useState<DisplayTask[]>([]);
    const [selectedTasks, setSelectedTasks] = React.useState<string[]>([]);
    const [taskFilter, setTaskFilter] = React.useState<string>("");
    const [fields, setFields] = useState<UpdateTypeMutationVariables>({
        id: editFields?.id || "",
        name: editFields?.name || "",
        duelTypes: editFields?.duelTypes || [],
        connectDuel: editFields?.connectDuel || false,
        createDuel: editFields?.createDuel || false,
        allTime: editFields?.allTime || false,
        paymentMethods: editFields?.paymentMethods || [],
        tasks: editFields?.tasks?.map((el) => el?.id) || [],
        periods:
            editFields?.periods?.map((el) => {
                return {
                    weekDay: el?.weekDay,
                    timeFrom: el?.timeFrom,
                    timeTo: el?.timeTo,
                };
            }) || [],
        events: editFields?.events || [],
    });

    const [updateType] = useUpdateTypeMutation({
        refetchQueries: (data) => {
            return [
                {
                    query: TYPES,
                },
            ];
        },
        onCompleted(data) {
            typeEditVar(null);
            setIsBtnDisabled(false);
            navigate(location.pathname + location.search);
        },
    });
    const [addType] = useAddTypeMutation({
        refetchQueries: () => {
            return [
                {
                    query: TYPES,
                },
            ];
        },
        onCompleted() {
            typeEditVar(null);
            setIsBtnDisabled(false);
            navigate(location.pathname + location.search);
        },
        onError() {
            setIsBtnDisabled(false);
        },
    });

    const submit = () => {
        setIsBtnDisabled(true);
        errors = errorsVar([]);
        const newErrors: successErrorType[] = [];

        if ((fields?.name || "").trim().length === 0) {
            newErrors.push({
                place: "manage-type.name",
                message: "Введите имя типа",
            });
        }
        if (editFields?.name === "Игрок" && fields?.name !== "Игрок") {
            newErrors.push({
                place: "manage-type.name",
                message: "Вы не можете изменить название для этого типа",
            });
        }
        if (editFields?.name === "Студент" && fields?.name !== "Студент") {
            newErrors.push({
                place: "manage-type.name",
                message: "Вы не можете изменить название для этого типа",
            });
        }
        if (!fields?.connectDuel && !fields?.createDuel) {
            newErrors.push({
                place: "manage-type.way-of-participate",
                message: "Выберите как минимум один способ участия",
            });
        }
        if (!fields?.allTime && Array.isArray(fields?.periods) && fields?.periods?.length === 0) {
            newErrors.push({
                place: "manage-type.periods",
                message: "Создайте как минимум один промежуток",
            });
        }
        if (fields?.paymentMethods?.length === 0) {
            newErrors.push({
                place: "manage-type.paymentMethods",
                message: "Выберите как минимум один способ оплаты",
            });
        }
        if (newErrors.length === 0) {
            editFields === null
                ? addType({
                      variables: {
                          ...fields,
                      },
                  })
                : updateType({
                      variables: {
                          ...fields,
                      },
                      onError(error) {
                          setIsBtnDisabled(false);

                          if (error.message === "This type name can't be changed ") {
                              newErrors.push({
                                  place: "manage-type.name",
                                  message: "Вы не можете изменить название для этого типа",
                              });
                              errorsVar(setError(errors, newErrors));
                          }
                      },
                  });
        } else {
            errorsVar(setError(errors, newErrors));
            setIsBtnDisabled(false);
        }
    };

    const changeTypes = React.useCallback(
        (types: DuelType[]) => {
            setFields({
                ...fields,
                duelTypes: types,
            });
            setSelectedTasks(
                filteredTasks
                    .filter((task) => {
                        const normalizedType = ["negotiation", "mini2"].includes(task.type) ? "mini" : task.type;
                        const normalizedTypes = types.map((item) =>
                            ["negotiation", "mini2"].includes(item) ? "mini" : item,
                        );
                        return normalizedTypes.includes(normalizedType) && task.selected;
                    })
                    .map((task) => task.id),
            );
        },
        [filteredTasks, setSelectedTasks],
    );

    const toggleSelection = React.useCallback(
        (taskId: string, add: boolean) => {
            if (add) {
                setSelectedTasks(_.uniq([...selectedTasks, taskId]));
                setFields((prevState) => {
                    return { ...prevState, tasks: _.uniq([...selectedTasks, taskId]) };
                });
            } else {
                const updatedTasks = [...selectedTasks];
                _.remove(updatedTasks, (task) => task === taskId);
                setSelectedTasks(updatedTasks);
                setFields((prevState) => {
                    return { ...prevState, tasks: updatedTasks };
                });
            }
        },
        [selectedTasks, setSelectedTasks],
    );

    const onChange = (index: number, period: Period) => {
        const newPeriods = _.cloneDeep(fields.periods!);
        newPeriods[index] = period;
        setFields({ ...fields, periods: newPeriods });
    };

    const onDelete = (index: number) => {
        const newFields = _.cloneDeep(fields);
        // @ts-ignore
        newFields.periods = [...fields.periods!.slice(0, index), ...fields.periods!.slice(index + 1)];
        setFields(newFields);
    };

    React.useEffect(() => {
        if (editFields !== null) {
            setSelectedTasks(Array.isArray(editFields?.tasks) ? editFields?.tasks.map((el) => el?.id) : []);
        }
    }, [editFields]);

    React.useEffect(() => {
        setTasks(
            (locationState?.allTasks || []).map((task) => {
                const isSelected = `${task?.id}`.length > 0 && selectedTasks.includes(`${task?.id}`);
                return {
                    id: task?.id || "",
                    name: task?.name || "",
                    searchArea: `${task?.description} ${task?.name} ${task?.role1} ${task?.role2} ${
                        isSelected ? "=" : ""
                    }`.toLowerCase(),
                    selected: isSelected,
                    type: task?.duelType as DuelType,
                };
            }),
        );
    }, [locationState, selectedTasks, setTasks, fields, setSelectedTasks]);

    React.useEffect(() => {
        const loweredFilter = taskFilter.toLowerCase();
        const filtered = tasks.filter((task) => {
            const normalizedType = ["negotiation", "mini2"].includes(task.type) ? "mini" : task.type;
            // @ts-ignore
            const normalizedTypes = fields?.duelTypes.map((item) =>
                ["negotiation", "mini2"].includes(item) ? "mini" : item,
            );
            return normalizedTypes.includes(normalizedType) && task.searchArea.includes(loweredFilter);
        });
        setFilteredTasks(filtered);
    }, [tasks, taskFilter, fields, setFilteredTasks]);

    React.useEffect(() => {
        if (editFields !== null) {
            setFields({
                ...editFields,
                tasks: editFields?.tasks?.map((el) => el?.id),
                periods: editFields?.periods?.map((el) => {
                    return {
                        weekDay: el?.weekDay,
                        timeFrom: el?.timeFrom,
                        timeTo: el?.timeTo,
                    };
                }),
            });
        }
    }, [editFields]);

    const title = editFields === null ? "Создать тип" : "Изменить тип";

    const toggleElementInArray = (array: any[], value: string) => {
        const index = array.indexOf(value);
        if (index !== -1) {
            return array.filter((element) => element !== value);
        } else {
            return [...array, value];
        }
    };

    const allErrors = errors.filter((el) =>
        [
            "manage-type.name",
            "manage-type.duel-types",
            "manage-type.tasks",
            "manage-type.way-of-participate",
            "manage-type.periods",
            "manage-type.paymentMethods",
        ].includes(el?.place || ""),
    );

    const select: ISelectItem[] = React.useMemo(
        () => [
            { name: "Экспресс", value: "express", selected: true },
            { name: "Мини", value: "mini", selected: false },
            { name: "Мини2", value: "mini2", selected: false },
            { name: "Конструктивные переговоры", value: "negotiation", selected: false },
        ],
        [],
    );

    const selectEvent: ISelectItem[] = React.useMemo(
        () => (locationState?.allEvents || []).map((event) => ({ name: event, value: event, selected: false })),
        [locationState.allEvents],
    );

    const nameError = errors.find((el) => el?.place === "manage-type.name");
    const duelTypesError = errors.find((el) => el?.place === "manage-type.duel-types");
    const tasksError = errors.find((el) => el?.place === "manage-type.tasks");
    const wayParticipateError = errors.find((el) => el?.place === "manage-type.way-of-participate");
    const periodsError = errors.find((el) => el?.place === "manage-type.periods");
    const paymentMethodsError = errors.find((el) => el?.place === "manage-type.paymentMethods");

    return (
        <div className="manage-type">
            <div className="manage-type__title">{title}</div>
            {allErrors.length > 0 && (
                <ErrorMessage
                    className="manage-type__error"
                    errors={allErrors}
                />
            )}
            <div className="manage-type__select-container">
                <Input
                    title={"Название типа"}
                    value={fields?.name}
                    className="manage-type_input input-title"
                    onChange={(val) => setFields({ ...fields, name: val })}
                    error={Boolean(nameError?.message)}
                    errorMessage={nameError?.message}
                    disabled={fields?.name === "Игрок" || fields?.name === "Студент"}
                />
                <div className="manage-type__select-wrap error-container">
                    <>
                        <div className="manage-type__select-title">ТИПЫ ПОЕДИНКОВ</div>
                        <select
                            className="manage-type__select"
                            multiple
                            // @ts-ignore
                            value={fields?.duelTypes?.map((el) => String(el))}
                            onChange={(e) => changeTypes(_.map(e.target.selectedOptions, "value") as DuelType[])}
                        >
                            {select?.map((el) => {
                                return (
                                    <option
                                        key={el?.value}
                                        className="manage-type__option"
                                        value={el?.value || ""}
                                    >
                                        {el?.name || ""}
                                    </option>
                                );
                            })}
                        </select>
                        {duelTypesError && (
                            <div className="manage-type__errorMessage-duelTypes">* {duelTypesError?.message}</div>
                        )}
                    </>
                </div>
                <div className="manage-type__select-container">
                    <Input
                        className="manage-type_input input-tasks"
                        title="Cитуации"
                        placeholder="Фильтр по названию"
                        value={taskFilter}
                        onChange={setTaskFilter}
                    />
                    <div className="manage-type__container error-container">
                        {
                            // tasks.length > 0 &&
                            <>
                                <table className="table manage-group__upper-table">
                                    <thead>
                                        <tr>
                                            <th>Ситуация ({selectedTasks.length} выбрано)</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="manage-type__bottom-table-wrapperContainer">
                                    <div className="manage-type__bottom-table-wrapper">
                                        <table className="table">
                                            <tbody>
                                                {filteredTasks.map((task) => (
                                                    <tr
                                                        className={task.selected ? "manage-type__table-selected" : ""}
                                                        key={task.id}
                                                        onClick={() => toggleSelection(task.id, !task.selected)}
                                                    >
                                                        <td>
                                                            {task.name}
                                                            {task.id === "" && <span>Неправильный ID ситуации</span>}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {filteredTasks.length === 0 && (
                                                    <tr>
                                                        <td className="manage-group__empty-row">
                                                            Нет подходящих ситуаций...
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }
                        {tasksError && <div className="manage-type__errorMessage-tasks">* {tasksError?.message}</div>}
                    </div>
                </div>
                <div className="manage-type-container error-container">
                    <p className="manage-type__subtitle">СПОСОБ УЧАСТИЯ</p>
                    <div className="manage-type_checkboxContainer">
                        <Checkbox
                            id={"1"}
                            title={"Подключение к поединку"}
                            name={"connectDuel"}
                            className="manage-type-checkbox"
                            checkboxValue={"1"}
                            value={fields?.connectDuel ? "1" : ""}
                            onChange={() =>
                                setFields({
                                    ...fields,
                                    connectDuel: !fields?.connectDuel,
                                })
                            }
                        />
                        <Checkbox
                            id={"2"}
                            title={"Создание поединка"}
                            name={"createDuel"}
                            className="manage-type-checkbox"
                            checkboxValue={"1"}
                            value={fields?.createDuel ? "1" : ""}
                            onChange={() =>
                                setFields({
                                    ...fields,
                                    createDuel: !fields?.createDuel,
                                })
                            }
                        />
                    </div>
                    {wayParticipateError && !fields?.connectDuel && !fields?.createDuel && (
                        <div className="manage-type__errorMessage-tasks">* {wayParticipateError?.message}</div>
                    )}
                </div>
                <div className="manage-type-container">
                    <p className="manage-type__subtitle">ВРЕМЯ УЧАСТИЯ</p>
                    <div className="manage-type_checkboxContainer">
                        <div className="radio-btns_mainContainer">
                            <div className="radio-btns_btnContainer">
                                <label>
                                    <input
                                        type="radio"
                                        name={"allTime"}
                                        value={3}
                                        checked={fields?.allTime}
                                        onChange={() =>
                                            setFields({
                                                ...fields,
                                                allTime: true,
                                            })
                                        }
                                    />
                                    <div className="radiobox"></div>
                                    <span>{"Без ограничения по времени"}</span>
                                </label>
                            </div>
                            <div className="radio-btns_btnContainer">
                                <label>
                                    <input
                                        type="radio"
                                        name={"limitedTime"}
                                        value={1}
                                        checked={!fields?.allTime}
                                        onChange={() =>
                                            setFields({
                                                ...fields,
                                                allTime: false,
                                            })
                                        }
                                    />
                                    <div className="radiobox"></div>
                                    <span>{"Назначить промежутки"}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {!fields?.allTime && (
                        <div className="manage-type__container error-container">
                            <PeriodList
                                type={""}
                                periods={fields?.periods as Period[]}
                                onChange={(index, period) => onChange(index, period)}
                                onDelete={(index) => onDelete(index)}
                                onAdd={() => {}}
                            />
                            {periodsError && Array.isArray(fields?.periods) && fields?.periods?.length === 0 && (
                                <div className="manage-type__errorMessage-tasks">* {periodsError?.message}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="manage-type-container">
                <p className="manage-type__subtitle">СПОСОБ ОПЛАТЫ УЧАСТИЯ</p>
                <div className="manage-type_paymentMethodsContainer error-container">
                    <Checkbox
                        id={"3"}
                        title={"Абонемент"}
                        name={"membership"}
                        className="manage-type-checkbox"
                        checkboxValue={"membership"}
                        value={fields?.paymentMethods?.includes("membership") ? "membership" : ""}
                        onChange={(val) => {
                            return setFields({
                                ...fields,
                                paymentMethods: toggleElementInArray(fields?.paymentMethods as string[], val as string),
                            });
                        }}
                    />
                    <Checkbox
                        id={"4"}
                        title={"Тренинг"}
                        name={"training"}
                        className="manage-type-checkbox"
                        checkboxValue={"training"}
                        value={fields?.paymentMethods?.includes("training") ? "training" : ""}
                        onChange={(val) => {
                            return setFields({
                                ...fields,
                                paymentMethods: toggleElementInArray(fields?.paymentMethods as string[], val as string),
                            });
                        }}
                    />
                    <Checkbox
                        id={"5"}
                        title={"Оплата поединка"}
                        name={"fee"}
                        className="manage-type-checkbox"
                        checkboxValue={"fee"}
                        value={fields?.paymentMethods?.includes("fee") ? "fee" : ""}
                        onChange={(val) => {
                            return setFields({
                                ...fields,
                                paymentMethods: toggleElementInArray(fields?.paymentMethods as string[], val as string),
                            });
                        }}
                    />
                    <Checkbox
                        id={"6"}
                        title={"Бесплатно"}
                        name={"free"}
                        className="manage-type-checkbox"
                        checkboxValue={"free"}
                        value={fields?.paymentMethods?.includes("free") ? "free" : ""}
                        onChange={(val) => {
                            return setFields({
                                ...fields,
                                paymentMethods: toggleElementInArray(fields?.paymentMethods as string[], val as string),
                            });
                        }}
                    />
                    {paymentMethodsError && fields?.paymentMethods?.length === 0 && (
                        <div className="manage-type__errorMessage-tasks">* {paymentMethodsError?.message}</div>
                    )}
                </div>
            </div>
            <div className="manage-type__select-wrap error-container">
                <>
                    <div className="manage-type__select-title">ДОСТУПНЫЕ МЕРОПРИЯТИЯ</div>
                    <select
                        className="manage-type__select"
                        multiple
                        // @ts-ignore
                        value={fields?.events?.map((el) => String(el))}
                        onChange={(e) =>
                            setFields({
                                ...fields,
                                events: _.map(e.target.selectedOptions, "value"),
                            })
                        }
                    >
                        {selectEvent?.map((el) => {
                            return (
                                <option
                                    key={el?.value}
                                    className="manage-type__option"
                                    value={el?.value || ""}
                                >
                                    {el?.name || ""}
                                </option>
                            );
                        })}
                    </select>
                    {duelTypesError && (
                        <div className="manage-type__errorMessage-duelTypes">* {duelTypesError?.message}</div>
                    )}
                </>
            </div>
            <Button
                text={"Сохранить"}
                onClick={() => submit()}
                disabled={isBtnDisabled}
                mode="red"
                className="login_btn"
            />
        </div>
    );
};

export default ManageType;
